import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { WeeducateScreen } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper } from "../../components/GridComponents";
import Heading from "../../components/Heading";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { breakpoints, rem, theme } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import NumberedBlocks, { NumberedBlockT } from "../general/NumberedBlocks";

const Text = styled.span`
  color: ${({ theme }): string => theme.colors.darkText};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(16)};
  }
`;

const Strong = styled(Text)`
  font-weight: 700;
`;

const Image = styled.img`
  width: 110%;
  margin-top: ${rem(100)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    margin-top: 0;
  }
`;

const EnterSection: FC = () => {
  const intl = useIntl();
  const { windowWidth } = useResize();
  const blocks: NumberedBlockT[] = [
    {
      text: putEmptyLineInsteadSpace(
        intl.formatMessage({ id: "weeducate.text_1" }),
        4
      ),
      width: 12
    },
    {
      text: putEmptyLineInsteadSpace(
        intl.formatMessage({ id: "weeducate.text_2" }),
        4
      ),
      width: 12
    },
    {
      text: intl.formatMessage({ id: "weeducate.text_3" }),
      width: 12
    },
    {
      text: intl.formatMessage({ id: "weeducate.text_4" }),
      width: 12
    }
  ];

  const splitedString = intl
    .formatMessage({ id: "weeducate.paragraph" })
    .split("|");

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <Block
          width={windowWidth < breakpoints.md ? 12 : 5}
          justifyContent="flex-start"
        >
          <Heading
            symbolColor={theme.colors.darkText}
            title={intl.formatMessage({
              id: "weeducate.title"
            })}
          />
          <Paragraph
            paragraph={
              <>
                <Strong>{splitedString[0]}</Strong>
                <Text>{splitedString[1]}</Text>
              </>
            }
          />
          <Spacer size="large" />
          <NumberedBlocks verticalDirection blocks={blocks} />
          <Spacer size="large" />
        </Block>
        <Block width={7}>
          <Image src={WeeducateScreen} />
        </Block>
      </GridWrapper>
    </Container>
  );
};

export default EnterSection;
