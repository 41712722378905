import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  WeeducateAppScreen1,
  WeeducateAppScreen2,
  WeeducateAppScreen3,
  WeeducateAppScreen4,
  WeeducateAppScreen5
} from "../../assets/images/Images";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { SIDEBAR_WIDTH } from "../SideBar";

const Wrapper = styled.div`
  background-color: #782de3;
  padding-left: ${rem(SIDEBAR_WIDTH)};
  margin-left: ${rem(-SIDEBAR_WIDTH)};
  max-height: ${rem(400)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    max-height: none;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  position: relative;
  top: ${rem(-70)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    flex-wrap: wrap;
  }
  ${({ theme }): string[] => [theme.media.maxMD]} {
    justify-content: center;
    top: ${rem(-40)};
  }
`;

const Screen = styled.img`
  width: 100%;
  height: 100%;
  max-width: ${rem(230)};
  margin-right: ${rem(50)};
  box-shadow: 0 22px 121px rgba(0, 0, 0, 0.25);
  ${({ theme }): string[] => [theme.media.maxLg]} {
    max-width: ${rem(180)};
    margin-right: ${rem(30)};
  }
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 18%;
    margin: 0 1%;
  }
  ${({ theme }): string[] => [theme.media.maxMD]} {
    width: 48%;
    margin-bottom: ${rem(20)};
  }
`;

const ScreenSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Wrapper>
        <Container>
          <ImagesWrapper>
            <Screen src={WeeducateAppScreen1} />
            <Screen src={WeeducateAppScreen2} />
            <Screen src={WeeducateAppScreen3} />
            <Screen src={WeeducateAppScreen4} />
            <Screen src={WeeducateAppScreen5} />
          </ImagesWrapper>
        </Container>
      </Wrapper>
      <Container>
        <Spacer size="extraLarge" />
        <Button
          externalRoute="https://weeducate.app/"
          variant="light"
          justifyContent="center"
          text={intl.formatMessage({
            id: "weeducate.button"
          })}
        />
        <Spacer mobileSize="extraLarge" size="small" />
      </Container>
    </>
  );
};

export default ScreenSection;
