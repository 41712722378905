import React, { FC } from "react";

import { WeeducatePerson } from "../../assets/images/Images";
import Layout from "../../components/Layout";
import Introduction, {
  WeeducatePersonImage
} from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";
import EnterSection from "../../partials/Weeducate/EnterSection";
import ScreensSection from "../../partials/Weeducate/ScreensSection";
import WalkthroughSection from "../../partials/Weeducate/WalkthroughSection";

const HomePage: FC = () => {
  const ID = "weeducate";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction
            caseStudyId={ID}
            picture={
              <WeeducatePersonImage
                src={WeeducatePerson}
                alt={"Weeducate person"}
              />
            }
          />
          <EnterSection />
          <WalkthroughSection />
          <ScreensSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default HomePage;
