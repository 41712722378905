import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { WeeducateWalkthrough } from "../../assets/images/Images";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import NumberedBlocks, { NumberedBlockT } from "../general/NumberedBlocks";
import { SIDEBAR_WIDTH } from "../SideBar";

const Wrapper = styled.div`
  background-color: #f9f6fc;
  color: ${({ theme }): string => theme.colors.white};
  padding: ${rem(50)} 0 0;
  padding-left: ${rem(SIDEBAR_WIDTH)};
  margin-left: ${rem(-SIDEBAR_WIDTH)};
  text-align: center;
`;

const Screen = styled.img`
  width: 100%;
  max-width: ${rem(850)};
  padding: ${rem(20)};
`;

const WalkthroughSection: FC = () => {
  const intl = useIntl();
  const blocks: NumberedBlockT[] = [
    {
      text: intl.formatMessage({ id: "weeducate.text_5" }),
      width: 3
    },
    {
      text: intl.formatMessage({ id: "weeducate.text_6" }),
      width: 3
    },
    {
      text: intl.formatMessage({ id: "weeducate.text_7" }),
      width: 3
    },
    {
      text: intl.formatMessage({ id: "weeducate.text_8" }),
      width: 3
    }
  ];

  return (
    <>
      <Wrapper>
        <Screen src={WeeducateWalkthrough} />
      </Wrapper>
      <Container>
        <Spacer mobileSize="large" size="extraLarge" />
        <NumberedBlocks initOrder={5} blocks={blocks} />
        <Spacer mobileSize="extraLarge" size="huge" />
      </Container>
    </>
  );
};

export default WalkthroughSection;
